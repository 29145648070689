import { Row, Col, Container, Card, Button, Table, Dropdown, Form } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import avatar from '../../assets/images/avatar.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import Rating from "../../components/rating/Rating";
import RatingStar from "../../components/rating/RatingStar";
import { useDispatch, useSelector } from "react-redux";
import { getRatings } from "../../redux/slices/users";


const Ratings = () => {

    const dispatch = useDispatch()

    const { business } = useSelector((state) => state.user)

    const [reviews, setReviews] = useState([])
    const [rating, setRating] = useState({})

    useEffect(() => {
        getAllReview()
    }, [0])


    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [showBalance, setShowBalance] = useState(false)

    const [transaction, setTransaction] = useState({})

    const showSuccessModal = () => {
        setForm(false)
        setShow(!show);
    }

    const getAllReview = () => {
        dispatch(getRatings())
        .then((response) => {
            if (response?.payload?.results) {
                setReviews(response.payload.results)
            }
            if (response?.payload?.rating) {
                setRating(response.payload.rating)
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <h3>Customer's Rating</h3>
                </Col>

                <div className="d-grid gap-4 mt-4">
                    <Rating 
                        showCounter={true}
                        size="34px"
                    />
                    <Row>
                        <Form.Group as={Col} md={6}>
                            <Form.Control
                                type="search"
                                placeholder="Search by ID"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                            <div className="filter-button gap-1">
                                <span>Filter by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                            <div className="filter-button gap-1">
                                <span>Sort by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </Row>

                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Reviews</th>
                                        <th>Ratings</th>
                                        <th>Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reviews?.length ? (
                                            reviews.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <p className="m-0 d-flex gap-2 align-items-center">
                                                            {/* <img src={item.avatar} alt="avatar" width="30" className="rounded-circle"/> */}
                                                            <strong>
                                                                {item.full_name}
                                                            </strong>
                                                        </p>
                                                    </td>
                                                    <td className="w-50 p-4">
                                                        {item.reviews}
                                                    </td>
                                                    <td>
                                                        <RatingStar 
                                                            count={item.rating_value} 
                                                            size="16px"
                                                        />
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            {item.date}
                                                            {/* <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => selectTransaction(item.id)}
                                                                    >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg> 
                                                                    View Ticket
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1 text-success"
                                                                        onClick={ () => selectTransaction(item.id) }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M6.86461 6.88236C8.17813 5.56432 9.99326 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 11.5858 4.41421 11.25 4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 16.8325 7.16751 20.75 12 20.75C16.8325 20.75 20.75 16.8325 20.75 12C20.75 7.16751 16.8325 3.25 12 3.25C9.57851 3.25 7.38558 4.23462 5.80213 5.82352C5.77668 5.84906 5.75345 5.87597 5.73245 5.90401L4.47488 4.64645C4.33769 4.50926 4.13353 4.46382 3.95111 4.52986C3.76869 4.59591 3.64094 4.76152 3.62338 4.95473L3.26982 8.84382C3.25639 8.9916 3.30929 9.13771 3.41422 9.24264C3.51914 9.34757 3.66526 9.40047 3.81304 9.38703L7.70213 9.03348C7.89534 9.01591 8.06095 8.88817 8.12699 8.70575C8.19304 8.52332 8.1476 8.31916 8.01041 8.18198L6.7823 6.95386C6.81101 6.93235 6.83854 6.90852 6.86461 6.88236Z" fill="#008000"/>
                                                                            <path d="M12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V12C11.25 12.2586 11.3832 12.4989 11.6025 12.636L14.6025 14.511C14.9538 14.7305 15.4165 14.6238 15.636 14.2725C15.8555 13.9212 15.7488 13.4585 15.3975 13.239L12.75 11.5843V7Z" fill="#008000"/>
                                                                        </svg> Transaction history
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1 text-danger"
                                                                        onClick={ () => selectTransaction(item.id) }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V3.75H5C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25H19C19.4142 5.25 19.75 4.91421 19.75 4.5C19.75 4.08579 19.4142 3.75 19 3.75H14.75V3C14.75 2.58579 14.4142 2.25 14 2.25H10Z" fill="#9F0A0A"/>
                                                                            <path d="M10 10.65C10.4142 10.65 10.75 10.9858 10.75 11.4L10.75 18.4C10.75 18.8142 10.4142 19.15 10 19.15C9.58579 19.15 9.25 18.8142 9.25 18.4L9.25 11.4C9.25 10.9858 9.58579 10.65 10 10.65Z" fill="#9F0A0A"/>
                                                                            <path d="M14.75 11.4C14.75 10.9858 14.4142 10.65 14 10.65C13.5858 10.65 13.25 10.9858 13.25 11.4V18.4C13.25 18.8142 13.5858 19.15 14 19.15C14.4142 19.15 14.75 18.8142 14.75 18.4V11.4Z" fill="#9F0A0A"/>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99142 7.91718C6.03363 7.53735 6.35468 7.25 6.73684 7.25H17.2632C17.6453 7.25 17.9664 7.53735 18.0086 7.91718L18.2087 9.71852C18.5715 12.9838 18.5715 16.2793 18.2087 19.5446L18.189 19.722C18.045 21.0181 17.0404 22.0517 15.7489 22.2325C13.2618 22.5807 10.7382 22.5807 8.25108 22.2325C6.95954 22.0517 5.955 21.0181 5.81098 19.722L5.79128 19.5446C5.42846 16.2793 5.42846 12.9838 5.79128 9.71852L5.99142 7.91718ZM7.40812 8.75L7.2821 9.88417C6.93152 13.0394 6.93152 16.2238 7.2821 19.379L7.3018 19.5563C7.37011 20.171 7.84652 20.6612 8.45905 20.747C10.8082 21.0758 13.1918 21.0758 15.5409 20.747C16.1535 20.6612 16.6299 20.171 16.6982 19.5563L16.7179 19.379C17.0685 16.2238 17.0685 13.0394 16.7179 9.88417L16.5919 8.75H7.40812Z" fill="#9F0A0A"/>
                                                                        </svg> Remove customer
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown> */}
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        ) 
                                        :
                                        <tr>
                                            <td colSpan={4} className="text-center">No review(s) available</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {/* <Paginator/> */}
                </div>

                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Voucher Balance</small>
                                                {transaction.amount} 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Valid Till</small>
                                            23-08-2023
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>

                <DrewsModal
                    show={showBalance}
                    onHide={() => setShowBalance(false)}
                    size="md"
                    dismissable={true}
                    title="Generate eVoucher"
                >
                    <Form>
                        <p>Create a bulk voucher easily</p>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Amount per PayCode</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0.00"
                                    name="amount"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Quantity</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0"
                                    name="quantity"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                />
                                <p className="text-muted text-sm mb-0">Create a security word to protect your vouchers</p>
                                <p className="text-danger text-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 8H12.01M12 11V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#9F0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                   <span className="mx-1">You will be charged 0.2% per Voucher</span> 
                                </p>
                            </Form.Group>

                            <Form.Group 
                                as={Col} 
                                md={12} 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={showSuccessModal}
                                >
                                    Create Payment Link
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title="Apply PayCode"
                >
                    <Form>
                        <p className="text-sm">New Paycode will be added to your wallet automatically.</p>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">PayCode</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="paycode"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0.00"
                                    name="amount"
                                />
                            </Form.Group>

                            <Form.Group 
                                as={Col} 
                                md={12} 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={showSuccessModal}
                                >
                                    Apply PayCode
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={show}
                    onHide={() => setShow(false)}
                    size="md"
                    dismissable={true}
                >
                    <Row className="justify-content-center">
                        <Col md={12}>
                           <div className="text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                    <path d="M28 0C22.4621 0 17.0486 1.64217 12.444 4.71885C7.83947 7.79553 4.25064 12.1685 2.13139 17.2849C0.0121319 22.4012 -0.542361 28.0311 0.538025 33.4625C1.61841 38.894 4.28515 43.8831 8.20102 47.799C12.1169 51.7149 17.106 54.3816 22.5375 55.462C27.969 56.5424 33.5988 55.9879 38.7151 53.8686C43.8315 51.7494 48.2045 48.1605 51.2812 43.556C54.3578 38.9514 56 33.5379 56 28C56 20.5739 53.05 13.452 47.799 8.20101C42.548 2.94999 35.4261 0 28 0ZM43.42 19.42L23.42 39.42C23.2341 39.6075 23.0129 39.7562 22.7692 39.8578C22.5254 39.9593 22.264 40.0116 22 40.0116C21.736 40.0116 21.4746 39.9593 21.2309 39.8578C20.9871 39.7562 20.7659 39.6075 20.58 39.42L10.58 29.42C10.3935 29.2335 10.2456 29.0121 10.1447 28.7685C10.0438 28.5249 9.99183 28.2637 9.99183 28C9.99183 27.4674 10.2034 26.9566 10.58 26.58C10.9566 26.2034 11.4674 25.9918 12 25.9918C12.5326 25.9918 13.0434 26.2034 13.42 26.58L22 35.18L40.58 16.58C40.9566 16.2034 41.4674 15.9918 42 15.9918C42.5326 15.9918 43.0434 16.2034 43.42 16.58C43.7966 16.9566 44.0082 17.4674 44.0082 18C44.0082 18.5326 43.7966 19.0434 43.42 19.42Z" fill="#B2B1FF"/>
                                </svg>
                            </div>
                            <div className="text-center mt-4">
                                <h5>PayCode added!</h5>
                                <p className="pe-5 ps-5">
                                    N24,000 Has been Successfully Credited to You!
                                </p>
                                <div className="mb-4 mt-4">
                                    <Button 
                                        type="button" 
                                        variant="light"
                                        className="form-control border border-1 bg-white text-primary"
                                        size="lg"
                                        onClick={() => setShow(false)}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                           </div>
                        </Col>
                    </Row>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default Ratings;