
import axiosInstance from '../utils/axios';

export default class ServiceHelper {
    static async handleGetRequest(url) {
        try {
            const res = await axiosInstance.get(url);
            return res.data;
        } catch (error) {
            throw error
        }
    }

    static async handlePostRequest(url, payload, headers = null) {
        try {
            const res = await axiosInstance.post(url, payload, headers);
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    static async handlePatchRequest(url, payload, headers = null) {
        try {
            const res = await axiosInstance.patch(url, payload, headers)
            return res.data;
        } catch (error) {
            throw error;
        }
    }
    static async handlePutRequest(url, payload, headers = null) {
        try {
            const res = await axiosInstance.put(url, payload, headers)
            return res.data;
        } catch (error) {
            throw error;
        }
    }
    static async handleDeleteRequest(url, headers = null) {
        try {
            const res = await axiosInstance.delete(url, headers)
            return res.data;
        } catch (error) {
            throw error;
        }
    }
}