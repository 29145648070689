import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/main.scss';

import { library } from '@fortawesome/fontawesome-svg-core'

import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Signup from './pages/auth/Signup';
import ProfileSetup from './pages/customer/ProfileSetup';
import Reports from './pages/Reports';
import Transactions from './pages/Transactions';
import Tickets from './pages/ticket';
import TicketView from './pages/ticket/View';
import Invoices from './pages/invoice';
import Paycode from './pages/paycode';
import Voucher from './pages/voucher';
import BillsPayment from './pages/billspayment';
import Wallets from './pages/wallets';
import P2pPayment from './pages/P2pPayment';
import Subscriptions from './pages/subscription';
import Plans from './pages/subscription/Plans';
import Customers from './pages/customer';
import Ratings from './pages/rating';
import Businesses from './pages/business';
import Messaging from './pages/messaging';
import Settings from './pages/settings';
import Payments from './pages/Payments';
import Sms from './pages/messaging/Sms';
import UniversalPaycode from './pages/paycode/UniversalPaycode';
import QrPayment from './pages/QrPayment';
import Announcement from './pages/Announcement';
import Audit from './pages/Audit';
import BusinessDetails from './pages/business/BusinessDetails';
import Profile from './pages/settings/Profile';
import AdminManagement from './pages/settings/AdminManagement';
import WithdrawalLimit from './pages/settings/WithdrawalLimit';
import TransferFees from './pages/settings/TransferFees';
import TransferLimit from './pages/settings/TransferLimit';
import Earning from './pages/Earning';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/profile-setup" element={<ProfileSetup />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/invoices/payment" element={<Payments />} />
      <Route path="/tickets" element={<Tickets />} />
      <Route path="/tickets/:id" element={<TicketView />} />
      <Route path="/paycodes" element={<Paycode />} />
      <Route path="/paycodes/generate" element={<UniversalPaycode />} />
      <Route path="/vouchers" element={<Voucher />} />
      <Route path="/bills-payment" element={<BillsPayment />} />
      <Route path="/wallets" element={<Wallets />} />
      <Route path="/p2p-payment" element={<P2pPayment />} />
      <Route path="/subscriptions" element={<Plans />} />
      <Route path="/subscriptions/plans" element={<Subscriptions />} />
      <Route path="/audit-trails" element={<Audit />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/ratings" element={<Ratings />} />
      <Route path="/businesses" element={<Businesses />} />
      <Route path="/businesses/:id" element={<BusinessDetails />} />
      <Route path="/messaging" element={<Messaging />} />
      <Route path="/messaging/sms" element={<Sms />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/payments" element={<Payments />} />
      <Route path="/payments/qr-pay/:id" element={<QrPayment />} />
      <Route path="/announcements" element={<Announcement />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/admin-management" element={<AdminManagement />} />
      <Route path="/withdrawal-limits" element={<WithdrawalLimit />} />
      <Route path="/transfer-fees" element={<TransferFees />} />
      <Route path="/transfer-limits" element={<TransferLimit />} />
      <Route path="/subscription-management" element={<Subscriptions />} />
      <Route path="/earnings" element={<Earning />} />
    </Routes>
  );
}

export default App;
library.add(fab, fas, far)