import { Row, Col, Container, Card, Button, Form, Modal, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState, useRef } from "react";
import phone from '../../assets/images/bills/phone.svg';
import bulb from '../../assets/images/bills/bulb.svg';
import network from '../../assets/images/bills/network.svg';
import water from '../../assets/images/bills/water.svg';
import tv from '../../assets/images/bills/tv.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import ServiceBox from "../../components/ServiceBox";
import { useDispatch, useSelector } from "react-redux";
import { fundDeposit, getWallets, withdrawalLimitRequest, withdrawalRequest } from "../../redux/slices/users";
import formatCurrency from "../../utils/currencyFormmter";
import { useNavigate } from "react-router-dom";
// import { createVirtualAccount } from "../../redux/slices/account.service";
import { PaystackButton } from "react-paystack";
import Swal from "sweetalert2";
import { createVirtualAccount } from "../../redux/slices/account.service";


const Wallets = () => {

    const publicKey = 'pk_test_741bc055b3cfde6f6c5244d89a38b99532d13ea2'

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)



    const services = [
        {
            id: 1,
            name: 'Bank',
            description:'Request a Bank Account',
            avatar: phone,
            buttonText: 'Request',
        },
        {
            id: 2,
            name: 'Withdraw',
            description:'Withdraw funds from wallet',
            avatar: network,
            buttonText: 'Withdraw Funds',
        },
        {
            id: 3,
            name: 'Funding',
            description:'Top-up my wallet balance',
            avatar: bulb,
            buttonText: 'Add Funds',
        },
        {
            id: 4,
            name: 'Limits',
            description:'Manage my Withdrawal limits',
            avatar: tv,
            buttonText: 'Manage Limits',
        },
        {
            id: 5,
            name: 'Subscription',
            description:'Manage my Subscription',
            avatar: water,
            buttonText: 'Manage Subscription',
        },
    ];

    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [limit, setLimit] = useState(false)
    const [withdrawal, setWithdrawal] = useState(false)
    const [funding, setFunding] = useState(false)
    const [success, setSuccessRespon] = useState(false)
    const [payload, setPayload] = useState({})
    const [errors, setErrors] = useState({})
    const [wallet, setWallet] = useState({})
    const [loading, setLoading] = useState(false)
    const [fee, setFee] = useState(0.00)
    const [pinValue, setpinValues] = useState(['', '', '', ''])

    const componentProps = {
        email: user?.email,
        amount: payload.fund_amount ? (parseFloat(payload.fund_amount) * 100) : 0,
        metadata: {
            phone: user?.phone,
        },
        publicKey,
        text: "Fund Wallet",
        onSuccess: (data) =>  {
            paymentVerification(data.trxref)
        },   
    }



    useEffect(() => {
        loadWallets()
        // dispatch(getBusiness())
        // .then((response) => {
        //     if(response?.payload[0]?.merchant_id) {
        //         console.log('payload', response.payload[0].merchant_id)
        //         setBusiness(response.payload[0])
        //     }
        // })
    }, [0])

    const showSuccessModal = () => {
        setForm(false)
        setSuccessRespon(!show);
    }

    const loadWallets = () => {
        dispatch(getWallets())
        .then((res) => { 
            if(res.payload?.length) {
                setWallet(res.payload[0])
            }
        })
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        if(name === 'amount' || name === 'fund_amount' || name === 'limit') {
            const newValue = value.replace(/[^\d.]/g, '')
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState)=> ({...prevState,[name]:value}))
        }

        // console.log(payload)
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if ((name ==='amount' || name === 'fund_amount' || name === 'limit') && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 200000) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: 'You can not withdraw above maximum limit' }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }

        if(name === 'amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 0) {
            calculateFees()
        }

    }

    const handleOtpInputs = (index, event) => {
        
        const value = event.target.value

        if(isNaN(value)) return;

        const pinValues = [...pinValue]
        pinValues[index] = value
        setpinValues(pinValues)

        if (value !== '' && index < 3) {
            inputRefs[index + 1].current.focus()
        }        
    }

    const calculateFees = ()  => {
        let amount = parseFloat(payload.amount);
        const percentage = (amount / 100) * 0.5
        const fee = percentage < 2500 ? percentage : 2500 
        setFee(fee)
        return fee
    }

    const paymentVerification = (reference) => {
        setLoading(true)
        dispatch(fundDeposit({reference: reference}))
        .then((response) => {
            console.log("FUNDING", response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setFunding(false)
                    loadWallets()
                })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    const sendWithrawalRequest = () => {
        if (!payload.amount) {return}
        if(!pinValue.length || pinValue.join("") === '' || pinValue.join("").length < 4) {
            setLoading(false)
            return;
        }

        setLoading(true)
        dispatch(withdrawalRequest({amount: payload.amount, pin: pinValue.join("")}))
        .then((response) => {
            // console.log(response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setWithdrawal(false)
                    loadWallets()
                })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const sendWithrawalLimitRequest = () => {
        if (!payload.limit) {return}

        setLoading(true)
        dispatch(withdrawalLimitRequest({requested_limit: payload.limit}))
        .then((response) => {
            console.log(response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setLimit(false)
                })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const handleRangeSlide = (event) => {
        const value = event.target.value;
        const newValue = value.replace(/[^\d.]/g, '')
        setPayload((prevState)=> ({...prevState,'limit':newValue}))
    }

    const requestVirtualAccount = () => {
        if(!payload.account_type &&( !payload.term || payload.term !== 'agreed')) { return }

        setLoading(true)
        dispatch(createVirtualAccount(payload))
        .then((e) => {
            // console.log(e)
            if(e.payload.success == true) {
                Swal.fire({
                    icon: 'success',
                    text: e.payload?.message
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: e.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <h3>Wallets</h3>
                </Col>

                <div className="d-grid gap-4 mt-3">
                    <Row className="data-card">
                        <Col md={6}>
                            <Card className="border-0 shadow-sm">
                                <Card.Body className="d-flex align-items-center gap-3 py-4 pe-3 ps-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                            <path d="M39.8201 8.18C39.2209 8.0597 38.6112 7.9994 38.0001 8H14.0001C13.4696 8 12.9609 7.78929 12.5859 7.41421C12.2108 7.03914 12.0001 6.53043 12.0001 6C12.0001 5.46957 12.2108 4.96086 12.5859 4.58579C12.9609 4.21071 13.4696 4 14.0001 4H38.0001C37.0686 2.75804 35.8608 1.75001 34.4722 1.05573C33.0837 0.361451 31.5525 0 30.0001 0H10.0001C8.44763 0 6.9165 0.361451 5.52795 1.05573C4.13939 1.75001 2.93155 2.75804 2.00008 4C0.693994 5.72683 -0.00869051 7.83488 8.11355e-05 10V38C8.11355e-05 40.6522 1.05365 43.1957 2.92901 45.0711C4.80438 46.9464 7.34792 48 10.0001 48H38.0001C40.6522 48 43.1958 46.9464 45.0711 45.0711C46.9465 43.1957 48.0001 40.6522 48.0001 38V18C47.997 15.6658 47.1776 13.4062 45.6836 11.6127C44.1896 9.81919 42.1153 8.60483 39.8201 8.18ZM34.4801 34C34.3207 34.0193 34.1595 34.0193 34.0001 34C32.4088 34 30.8827 33.3679 29.7574 32.2426C28.6322 31.1174 28.0001 29.5913 28.0001 28C28.0001 26.4087 28.6322 24.8826 29.7574 23.7574C30.8827 22.6321 32.4088 22 34.0001 22C34.6832 21.9957 35.3613 22.1177 36.0001 22.36C37.2747 22.8321 38.3513 23.7229 39.0536 24.8868C39.7558 26.0506 40.0421 27.4183 39.8655 28.7661C39.6889 30.1138 39.0599 31.3617 38.0815 32.3053C37.1031 33.2489 35.8333 33.8323 34.4801 33.96V34Z" fill="#B2B1FF"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h4>{formatCurrency(wallet?.amount).with_currency}</h4>
                                        <p className="m-0">Avail. Wallet balance</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {
                        services.length ? (
                            <div className="services">
                                {
                                    services.map((service, index) => (
                                        <ServiceBox 
                                            key={index}
                                            text={service.description}
                                            buttonText={service.buttonText}
                                            onClick={ 
                                                service.name === 'Limits' ? 
                                                    () => setLimit(true) 
                                                : 
                                                service.name === 'Withdraw' ?
                                                    () => setWithdrawal(true)
                                                :
                                                service.name === 'Subscription' ?
                                                    () => navigate('/subscriptions')
                                                :
                                                service.name === 'Funding' ?
                                                    () => setFunding(true)
                                                :
                                                    () => setForm(true)
                                            }
                                        >
                                            <div className="bg--primary">
                                                {
                                                    service.name.toLowerCase() === 'bank' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M6.66667 11.9997V22.6664M12.6667 11.9997V22.6664M19.3333 11.9997V22.6664M25.3333 11.9997V22.6664M4 24.7997L4 25.8664C4 26.6131 4 26.9865 4.14532 27.2717C4.27316 27.5226 4.47713 27.7265 4.72801 27.8544C5.01323 27.9997 5.3866 27.9997 6.13333 27.9997H25.8667C26.6134 27.9997 26.9868 27.9997 27.272 27.8544C27.5229 27.7265 27.7268 27.5226 27.8547 27.2717C28 26.9865 28 26.6131 28 25.8664V24.7997C28 24.053 28 23.6796 27.8547 23.3944C27.7268 23.1435 27.5229 22.9395 27.272 22.8117C26.9868 22.6664 26.6134 22.6664 25.8667 22.6664H6.13333C5.3866 22.6664 5.01323 22.6664 4.72801 22.8117C4.47713 22.9395 4.27316 23.1435 4.14532 23.3944C4 23.6796 4 24.053 4 24.7997ZM15.5372 4.10254L5.67055 6.29513C5.07447 6.4276 4.77643 6.49383 4.55395 6.65411C4.35772 6.79548 4.20364 6.98756 4.1082 7.20979C4 7.46173 4 7.76705 4 8.37767L4 9.86637C4 10.6131 4 10.9865 4.14532 11.2717C4.27316 11.5226 4.47713 11.7265 4.72801 11.8544C5.01323 11.9997 5.3866 11.9997 6.13333 11.9997H25.8667C26.6134 11.9997 26.9868 11.9997 27.272 11.8544C27.5229 11.7265 27.7268 11.5226 27.8547 11.2717C28 10.9865 28 10.6131 28 9.86637V8.37767C28 7.76705 28 7.46174 27.8918 7.20979C27.7964 6.98756 27.6423 6.79548 27.446 6.65411C27.2236 6.49383 26.9255 6.4276 26.3295 6.29514L16.4628 4.10254C16.2901 4.06416 16.2037 4.04498 16.1165 4.03732C16.039 4.03053 15.961 4.03053 15.8835 4.03732C15.7963 4.04498 15.7099 4.06416 15.5372 4.10254Z" stroke="#443792" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    )
                                                    : 
                                                    service.name.toLowerCase() === 'withdraw' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M8.8 13.3333H8.26667C6.77319 13.3333 6.02646 13.3333 5.45603 13.0427C4.95426 12.787 4.54631 12.3791 4.29065 11.8773C4 11.3069 4 10.5601 4 9.06667V8.26667C4 6.77319 4 6.02646 4.29065 5.45603C4.54631 4.95426 4.95426 4.54631 5.45603 4.29065C6.02646 4 6.77319 4 8.26667 4H23.7333C25.2268 4 25.9735 4 26.544 4.29065C27.0457 4.54631 27.4537 4.95426 27.7094 5.45603C28 6.02646 28 6.77319 28 8.26667V9.06667C28 10.5601 28 11.3069 27.7094 11.8773C27.4537 12.3791 27.0457 12.787 26.544 13.0427C25.9735 13.3333 25.2268 13.3333 23.7333 13.3333H23.2M11.9171 27.9831C12.9585 26.9418 12.9585 25.2533 11.9171 24.2119C10.8757 23.1705 9.18723 23.1705 8.14583 24.2119M20.5517 27.9831C19.5103 26.9418 19.5103 25.2533 20.5517 24.2119C21.5931 23.1705 23.2815 23.1705 24.3229 24.2119M24 23.7333V13.6C24 12.1065 24 11.3598 23.7094 10.7894C23.4537 10.2876 23.0457 9.87965 22.544 9.62398C21.9735 9.33333 21.2268 9.33333 19.7333 9.33333H12.2667C10.7732 9.33333 10.0265 9.33333 9.45603 9.62398C8.95426 9.87965 8.54631 10.2876 8.29065 10.7894C8 11.3598 8 12.1065 8 13.6V23.7333C8 25.2268 8 25.9735 8.29065 26.544C8.54631 27.0457 8.95426 27.4537 9.45603 27.7094C10.0265 28 10.7732 28 12.2667 28H19.7333C21.2268 28 21.9735 28 22.544 27.7094C23.0457 27.4537 23.4537 27.0457 23.7094 26.544C24 25.9735 24 25.2268 24 23.7333ZM18.6667 16C18.6667 17.4728 17.4728 18.6667 16 18.6667C14.5272 18.6667 13.3333 17.4728 13.3333 16C13.3333 14.5272 14.5272 13.3333 16 13.3333C17.4728 13.3333 18.6667 14.5272 18.6667 16Z" stroke="#443792" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    )
                                                    : 
                                                    service.name.toLowerCase() === 'funding' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M20.6667 16.0002C20.6667 14.8956 21.5621 14.0002 22.6667 14.0002C23.7712 14.0002 24.6667 14.8956 24.6667 16.0002C24.6667 17.1047 23.7712 18.0002 22.6667 18.0002C21.5621 18.0002 20.6667 17.1047 20.6667 16.0002Z" fill="#443792"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2551 8.89886C26.3781 6.83797 24.4402 5.35647 22.1342 5.11381L21.2651 5.02234C16.8753 4.56041 12.4477 4.59087 8.06467 5.11316L7.48875 5.18179C5.2637 5.44694 3.50069 7.18535 3.20432 9.40647C2.62037 13.7829 2.62037 18.2175 3.20432 22.5939C3.50069 24.815 5.2637 26.5534 7.48876 26.8186L8.06468 26.8872C12.4477 27.4095 16.8753 27.44 21.2651 26.978L22.1342 26.8866C24.4402 26.6439 26.3781 25.1624 27.2551 23.1015C28.6408 22.6889 29.6984 21.4876 29.872 20.0033C30.183 17.3436 30.183 14.6567 29.872 11.997C29.6984 10.5127 28.6408 9.31142 27.2551 8.89886ZM21.0558 7.01136C16.8143 6.56503 12.5362 6.59446 8.30133 7.09911L7.72541 7.16774C6.40699 7.32485 5.36236 8.35491 5.18675 9.67099C4.62623 13.8718 4.62623 18.1285 5.18675 22.3294C5.36236 23.6455 6.40699 24.6755 7.72541 24.8326L8.30133 24.9012C12.5362 25.4059 16.8143 25.4353 21.0558 24.989L21.9249 24.8975C23.059 24.7782 24.0624 24.2192 24.7578 23.3894C22.7469 23.5067 20.7093 23.4543 18.7223 23.2322C17.0299 23.0431 15.6611 21.7104 15.4614 20.0033C15.1503 17.3436 15.1503 14.6567 15.4614 11.997C15.6611 10.29 17.0299 8.95728 18.7223 8.76813C20.7093 8.54605 22.7469 8.49365 24.7578 8.61091C24.0624 7.78116 23.059 7.22216 21.9249 7.10282L21.0558 7.01136ZM25.7032 10.6864C25.704 10.6916 25.7048 10.6967 25.7056 10.7018L25.7136 10.7536L25.9785 10.7125C26.1155 10.7261 26.2524 10.7405 26.3889 10.7557C27.1721 10.8433 27.7958 11.4621 27.8855 12.2294C28.1785 14.7347 28.1785 17.2657 27.8855 19.771C27.7958 20.5382 27.1721 21.1571 26.3889 21.2446C26.2524 21.2599 26.1155 21.2743 25.9785 21.2878L25.7136 21.2467L25.7056 21.2986C25.7048 21.3037 25.704 21.3088 25.7032 21.3139C23.465 21.5164 21.1696 21.4933 18.9445 21.2446C18.1613 21.1571 17.5376 20.5382 17.4479 19.771C17.1549 17.2657 17.1549 14.7347 17.4479 12.2294C17.5376 11.4621 18.1613 10.8433 18.9445 10.7557C21.1696 10.5071 23.465 10.484 25.7032 10.6864Z" fill="#443792"/>
                                                        </svg>
                                                    )
                                                    : 
                                                    service.name.toLowerCase() === 'limits' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M8.8 13.3333H8.26667C6.77319 13.3333 6.02646 13.3333 5.45603 13.0427C4.95426 12.787 4.54631 12.3791 4.29065 11.8773C4 11.3069 4 10.5601 4 9.06667V8.26667C4 6.77319 4 6.02646 4.29065 5.45603C4.54631 4.95426 4.95426 4.54631 5.45603 4.29065C6.02646 4 6.77319 4 8.26667 4H23.7333C25.2268 4 25.9735 4 26.544 4.29065C27.0457 4.54631 27.4537 4.95426 27.7094 5.45603C28 6.02646 28 6.77319 28 8.26667V9.06667C28 10.5601 28 11.3069 27.7094 11.8773C27.4537 12.3791 27.0457 12.787 26.544 13.0427C25.9735 13.3333 25.2268 13.3333 23.7333 13.3333H23.2M11.9171 27.9831C12.9585 26.9418 12.9585 25.2533 11.9171 24.2119C10.8757 23.1705 9.18723 23.1705 8.14583 24.2119M20.5517 27.9831C19.5103 26.9418 19.5103 25.2533 20.5517 24.2119C21.5931 23.1705 23.2815 23.1705 24.3229 24.2119M24 23.7333V13.6C24 12.1065 24 11.3598 23.7094 10.7894C23.4537 10.2876 23.0457 9.87965 22.544 9.62398C21.9735 9.33333 21.2268 9.33333 19.7333 9.33333H12.2667C10.7732 9.33333 10.0265 9.33333 9.45603 9.62398C8.95426 9.87965 8.54631 10.2876 8.29065 10.7894C8 11.3598 8 12.1065 8 13.6V23.7333C8 25.2268 8 25.9735 8.29065 26.544C8.54631 27.0457 8.95426 27.4537 9.45603 27.7094C10.0265 28 10.7732 28 12.2667 28H19.7333C21.2268 28 21.9735 28 22.544 27.7094C23.0457 27.4537 23.4537 27.0457 23.7094 26.544C24 25.9735 24 25.2268 24 23.7333ZM18.6667 16C18.6667 17.4728 17.4728 18.6667 16 18.6667C14.5272 18.6667 13.3333 17.4728 13.3333 16C13.3333 14.5272 14.5272 13.3333 16 13.3333C17.4728 13.3333 18.6667 14.5272 18.6667 16Z" stroke="#443792" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    )
                                                    : 
                                                    service.name.toLowerCase() === 'subscription' ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M18.6666 14C18.6666 15.8409 15.6818 17.3333 11.9999 17.3333C8.31802 17.3333 5.33325 15.8409 5.33325 14M18.6666 14C18.6666 12.1591 15.6818 10.6667 11.9999 10.6667C8.31802 10.6667 5.33325 12.1591 5.33325 14M18.6666 14V25.3333C18.6666 26.8061 15.6818 28 11.9999 28C8.31802 28 5.33325 26.8061 5.33325 25.3333V14M18.6666 20C18.6666 21.4728 15.6818 22.6667 11.9999 22.6667C8.31802 22.6667 5.33325 21.4728 5.33325 20M26.6666 7.33333C26.6666 5.49238 23.6818 4 19.9999 4C17.3611 4 15.0804 4.76657 13.9999 5.87862M26.6666 7.33333C26.6666 8.56714 25.3259 9.64438 23.3333 10.2207M26.6666 7.33333V18.6667C26.6666 19.6537 25.3259 20.5155 23.3333 20.9766M26.6666 13.3333C26.6666 14.3422 25.2659 15.2203 23.1998 15.6733" stroke="#443792" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    )
                                                    : null
                                                }
                                            </div>
                                        </ServiceBox>
                                    ))
                                }
                            </div>

                        ) : null
                    }
                </div>

                <DrewsModal
                    show={limit}
                    onHide={() => setLimit(false)}
                    size="md"
                    dismissable={true}
                    title="Manage Withdrawal Limit"
                >
                    <Form>
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="text-sm">Current Withdrawal Limit</p>
                            <p className="text-sm text-primary">{ business.withdrawal_limit ? formatCurrency(business.withdrawal_limit).with_currency : '₦0.00' } </p>

                        </div>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Request New limit</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="limit"
                                    placeholder="₦56,000.00"
                                    required
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.limit) > 0 ? formatCurrency(payload.limit).formatted : ''}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Range 
                                    className="custom-range"
                                    defaultValue={ business.withdrawal_limit }
                                    onChange={ handleRangeSlide }
                                    min={20000} 
                                    max={10000000} 
                                />
                            </Form.Group>

                            <Form.Group 
                                as={Col} 
                                md={12} 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={sendWithrawalLimitRequest}
                                >
                                    Request  New Limit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title="Add a Bank"
                >
                    <Form>
                        <p className="text-sm">We wil be creating a virtual account for your business. Kindly fill the necessary details </p>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Bank</Form.Label>
                                <Form.Select className="form-control" name="account_type" onChange={handleInput}>
                                    <option value="">---Select a Bank ---</option>
                                    <option value="wema">Wema Bank</option>
                                    <option value="gt">GTBank</option>
                                    <option value="sterling">Sterling Bank</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Check
                                    required
                                    label="I accept the Terms and conditions for creating a virtual account "
                                    feedback="You must agree before submitting."
                                    feedbackType="invalid"
                                    value="agreed"
                                    name="term"
                                    onChange={handleInput}
                                />
                            </Form.Group>

                            <Form.Group 
                                as={Col} 
                                md={12} 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    type="button" 
                                    variant="primary" 
                                    className="mb-0 border-0 mt-2 mb-3"
                                    onClick={requestVirtualAccount}
                                    disabled={payload.account_type == null ||  payload.term == null}
                                >
                                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>                               
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                                {/* Wallet funding modal */}
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={funding}
                    onHide={() => setFunding(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Fund your Wallet</Modal.Header>
                    <Modal.Body className="p-5">
                        <Row>
                            <Form.Group as={Col} md={12} controlId="validationCustom01" className="mb-3">
                                <Form.Label className="mb-0 text-sm">Amount</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="₦0.00"
                                    name="fund_amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.fund_amount) > 0 ? formatCurrency(payload.fund_amount).formatted : ''}
                                />
                                {
                                    errors['fund_amount'] && 
                                    <Form.Text className="text-danger">{errors['fund_amount']}</Form.Text>
                                }
                            </Form.Group>
                        </Row>
                        <Form.Group className="mt-4">
                            {/* <Button variant="primary" className="form-control">Fund Wallet</Button> */}
                            <PaystackButton className="btn btn-primary form-control" {...componentProps} />
                        </Form.Group>
                    </Modal.Body>
                </Modal>

                {/* Wallet withdrawal modal */}
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={withdrawal}
                    onHide={() => setWithdrawal(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Withdrawal Request</Modal.Header>
                    <Modal.Body className="p-4">
                        <Form>
                            <Row>
                                <Form.Group as={Col} md={12} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Amount(NGN)</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="₦0.00"
                                        name="amount"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                    />
                                    {
                                        errors['amount'] && 
                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                    }
                                    <p className="m-0 text-secondary">
                                        <small>Maximum Limit: {formatCurrency(2000000).with_currency}</small>
                                    </p>
                                </Form.Group>
                                <Form.Group as={Col} md={12} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Transaction Fee</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="₦0.00"
                                        name="total_amount"
                                        disabled
                                        value={fee}
                                    />
                                    <p className="m-0 text-secondary">
                                        <small>0.5% fees on each withdrawal capped @ {formatCurrency(2500).with_currency}</small>
                                    </p>
                                </Form.Group>
                                <Form.Group>
                                    <p>4-Digit Transaction PIN</p>
                                    <div className="otp-boxes w-50">
                                        {
                                            Array.from({length: 4}, (item, indexx) => (
                                                <input 
                                                    type="text" 
                                                    maxLength={1} 
                                                    minLength={1} 
                                                    className="box" 
                                                    key={indexx}
                                                    onChange={ (e) => handleOtpInputs(indexx, e) }
                                                    ref={ inputRefs[indexx] }
                                                />
                                            ))
                                        }
                                    </div>
                                    <small className="mt-1 text-muted">No wallet Pin no withdrawal</small>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mt-4">
                                <Button 
                                    type="button"
                                    variant="primary" 
                                    className="form-control" 
                                    size="lg"
                                    onClick={sendWithrawalRequest}
                                    disabled={Object.keys(errors).length > 0 || pinValue.join("").length < 4}
                                >
                                    Withdraw <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>

            </Container>
        </Layout>
    );
}

export default Wallets;