import { Col, Container, Form, Row, Button, Card, Spinner, FormGroup, Modal } from "react-bootstrap";
import Logo from "../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { getInvoice, getTransferCharges, payInvoice } from "../redux/slices/account.service";
import { PaystackButton } from "react-paystack";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
import formatCurrency from "../utils/currencyFormmter";
import { rateCustomer } from "../redux/slices/users";
import CustomerRating from "../components/hmo";

const Payments = () => {

    const publicKey = 'pk_test_741bc055b3cfde6f6c5244d89a38b99532d13ea2'

    const initialState = {
        method: 'card',
        loading: false,
        show_rating_form: false,
        payload: {},
        invoice: {},
        errors: {},
        fee: 0.00,
        fees: [],
        // amount: state.invoice?.amount || 0
    }
    
    const [method, setMethod] = useState('card')
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({})
    const [searchParams, setSearchParams] = useSearchParams()
    const [invoice, setInvoice] = useState({})
    const [errors, setErrors] = useState({})
    const [fee, setFee]  = useState(0.00)
    const [fees, setFees] = useState([])
    const [amount, setAmount] = useState(invoice?.amount || 0)

    const [state, setState] = useState(initialState)
    
    const dispatch = useDispatch();
    
    const componentProps = {
        email: payload?.email,
        amount: (amount * 100),
        metadata: {
          phone: payload?.phone_number, 
        },
        publicKey,
        text: `Pay ${formatCurrency(amount).with_currency}`,
        onSuccess: (data) => {
            verifyPayment(data.trxref)
        },
    }
    
    useEffect(() => {
        if(searchParams.get('ref')) {
            getInvoiceData(searchParams.get('ref'))
        }
        getFees()
    }, [fee]) 


    const handleSelection = (event) => {
        const { name, value } = event.target
        setMethod(value)
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }
    

    const payWithPaycode = () => {
        if (!payload.token && !invoice.amount && !payload.secret) return;
        setLoading(true)

        let request_payload = payload
        request_payload.amount = invoice.amount
        request_payload.pay_mode = 'paycode'
        request_payload.invoice_id = invoice?.ref

        dispatch(payInvoice(request_payload))
        .then((res) => {
            console.log("INVOICE", res)
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message
                })
                .then(() => {
                    setState((prevState)=> ({...prevState, 'show_rating_form': true}))
                })
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const getInvoiceData = (reference) => {
        dispatch(getInvoice(reference))
        .then((res) => {
            if(res.payload?.results) {
                let invoice = res.payload.results.filter(inv => inv.ref === reference)[0]
                calculateFee(parseFloat(invoice.amount))
                setInvoice(invoice)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'Invoice not found'
                })
            }
        })
    }

    const getFees = () => {
        dispatch(getTransferCharges())
        .then((res) => {
            if(res.payload?.length) {
                setFees(res.payload)
                calculateFee(parseFloat(invoice.amount))
            }
        })
    }

    const verifyPayment = (ref) => {
        dispatch(payInvoice({payment_reference: ref, invoice_id: invoice?.ref, pay_mode: 'paystack'}))
        .then((res) => {
            if(res.payload?.status === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message
                })
                .then(() => {
                    setState((prevState)=> ({...prevState, 'show_rating_form': true}))
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
    }

    const calculateFee = (amount) => {
        let charge = 0
        let percentage = 0
        if(fees.length) {
            charge = fees.filter(fee => fee.name === 'paystack')[0].value
            percentage = (amount / 100) * charge
            setFee(percentage)
        }
        setAmount(amount + percentage)
    }
    
    return (
        <Row className="full-height justify-content-center">
            <Col md={10} lg={7} sm={12} className="px-5">
                <Container className="mt-5 text-center px-5">
                    <div className="mt-3 mb-5">
                        <Logo variant="dark"/>
                    </div>
                </Container>
                <Container className="px-5 px-md-5 mt-5">
                    <Card className="border-0 shadow">
                        <CardHeader className="justify-content-between d-flex">
                            <h5>Payment Methods</h5>
                            <h5>{formatCurrency(amount).with_currency || ''}</h5>
                        </CardHeader>
                        <Card.Body>
                            <p>Please select a payment option to complete your payment</p>
                            <Row>
                                <Col md={3}>
                                    <Form.Check
                                        type="radio"
                                        className="text-primary font-bold"
                                        label="Card"
                                        name="payment_method"
                                        onChange={handleSelection}
                                        value="card"
                                        checked={ method === "card" }
                                    />
                                    <Form.Check
                                        type="radio"
                                        className="text-primary font-bold"
                                        label="Bank"
                                        name="payment_method"
                                        onChange={handleSelection}
                                        value="bank"
                                    />
                                    <Form.Check
                                        type="radio"
                                        className="text-primary font-bold"
                                        label="PayCode"
                                        name="payment_method"
                                        onChange={handleSelection}
                                        value="paycode"
                                    />
                                </Col>
                                <Col md={9}>
                                    {
                                        method === 'card' ? (
                                            <>
                                                <h5>Credit/Debit Card</h5>
                                                <hr/>
                                                <Form>
                                                    <Row>
                                                        <Form.Group as={Col} md={12} className="mb-2">
                                                            <Form.Text>Email</Form.Text>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder="Email"
                                                                required
                                                                name="email"
                                                                onChange={handleInput}
                                                                onKeyUp={handleValidation}
                                                            />
                                                            {
                                                                errors['email'] && 
                                                                <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={12} className="mb-2">
                                                            <Form.Text>Phone Number</Form.Text>
                                                            <Form.Control
                                                                type="tel"
                                                                placeholder="Phone Number"
                                                                required
                                                                name="phone_number"
                                                                maxLength={11}
                                                                minLength={11}
                                                                onChange={handleInput}
                                                                onKeyUp={handleValidation}
                                                            />
                                                            {
                                                                errors['phone_number'] && 
                                                                <Form.Text className="text-danger">{errors['phone_number']}</Form.Text>
                                                            }
                                                        </Form.Group>
                                                    </Row>
                                                    <p className="text-primary font-bold">You will be charged {formatCurrency(fee).with_currency} as service fee. </p>
                                                </Form>
                                                <PaystackButton className="btn btn-primary" {...componentProps} />
                                            </>
                                        )
                                        : 
                                        method === 'bank' ? (
                                            <>
                                                <h5>Transfer via Bank</h5>
                                                <hr/>
                                                <p>Pay into the business account:</p>
                                                {
                                                    invoice?.merchant_banks?.length ? (
                                                        invoice?.merchant_banks.map(bank => (
                                                            <div className="mb-3" key={bank.id}>
                                                                <p className="m-0">Account Number: <strong>{  bank.account_number }</strong></p>
                                                                <p className="m-0">Account Name: <strong>{ bank.account_name }</strong></p>
                                                                <p className="m-0">Bank Name: <strong>{ bank.bank_name }</strong></p>
                                                                <p className="m-0">Payment Reference: <strong>{ invoice?.ref }</strong></p>
                                                                <p className="text-primary font-bold text-sm mt-2">Please ensure to use the above payment reference in your payment narration.</p>
                                                                <hr />
                                                            </div>
                                                        ))
                                                    ) : null
                                                }
                                            </>
                                        )
                                        : 
                                        method === 'paycode' ? (
                                            <>
                                            <h5>PayCode Payment</h5>
                                            <hr/>
                                            <Row>
                                                <FormGroup as={Col} md={12} className="mb-2">
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control
                                                        required
                                                        placeholder="N0.00"
                                                        name="amount"
                                                        disabled
                                                        // onChange={handleInput}
                                                        // onKeyUp={handleValidation}
                                                        value={ parseFloat(invoice?.amount) > 0 ? formatCurrency(invoice?.amount).formatted : ''}
                                                    />
                                                    {
                                                        errors['amount'] && 
                                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                                    }
                                                </FormGroup>
                                                <FormGroup as={Col} md={12} className="mb-2">
                                                    <Form.Label>PayCode</Form.Label>
                                                    <Form.Control
                                                        required
                                                        placeholder="paycode token"
                                                        name="token"
                                                        onChange={handleInput}
                                                        onKeyUp={handleValidation}
                                                        value={payload?.token || ''}
                                                        maxLength={6}
                                                        minLength={6}
                                                    />
                                                    {
                                                        errors['token'] && 
                                                        <Form.Text className="text-danger">{errors['token']}</Form.Text>
                                                    }
                                                </FormGroup>
                                                <FormGroup as={Col} md={12} className="mb-2">
                                                    <Form.Label>Secret</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        required
                                                        placeholder="Secret"
                                                        name="secret"
                                                        onChange={handleInput}
                                                        // maxLength={6}
                                                        // minLength={4}
                                                        onKeyUp={handleValidation}
                                                    />
                                                    {
                                                        errors['secret'] && 
                                                        <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                                    }
                                                </FormGroup>
                                                <FormGroup as={Col} md={12} className="mb-2">
                                                    <Button
                                                        type="button" 
                                                        variant="primary" 
                                                        onClick={payWithPaycode}
                                                        disabled={Object.keys(errors).length > 0}
                                                    >
                                                        Pay now  <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                    </Button>
                                                </FormGroup>
                                            </Row>
                                            </>
                                        )
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>




                    {/* Customer rating */}
                    <CustomerRating
                        merchant={invoice.merchant_data ?? ''}
                        show_form={state.show_rating_form}
                        hide_form={ () => setState((prevState)=> ({...prevState, 'show_rating_form': false})) }
                    />
                </Container>
            </Col>
        </Row>
    ); 
}


export default Payments;