import { Col, Container, Row, Card, Button, Modal, Table } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataCardSm from "../../components/analytics/DataCardSm";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getBusinessById, manageBusinessStatus } from "../../redux/slices/account.service";
import { useNavigate, useParams } from "react-router-dom";
import dateFormatter from "../../utils/dateFormatter";
import formatCurrency from "../../utils/currencyFormmter";
import { QRCodeSVG } from "qrcode.react";
import printer from "../../assets/images/printer2.svg";
import card_logo from "../../assets/images/card.png";
import wema from "../../assets/images/wema.svg";
import bank from "../../assets/images/gtb.svg";


const BusinessDetails = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id } = useParams()


    const [state, setState] = useState({show_modal: false, print_screen: false, details: {}})

    useEffect(() => {
        if(id) {
            loadBusinessDetails()
        } 
    },[id])

    const handleManagement = (option) => {
        Swal.fire({
            icon: 'warning',
            text: `Are you sure you want to ${option} this business?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if (!result.isConfirmed) return;
            manageBusiness(option)
        })
    }

    const manageBusiness = (option) => {
        dispatch(manageBusinessStatus({id, option}))
        .then(res => {
            if(res.payload?.success == true){
                setState({...state, show_modal: false})
                loadBusinessDetails()
                Swal.fire({
                    icon: "success",
                    text: res.payload?.message
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: res.payload.message ??  "Something went wrong!"
                })
            }
        })
    }

    const loadBusinessDetails = () => {
        dispatch(getBusinessById(id))
        .then((result) => {
            if(result.payload.data) {
                setState((prevState)=> ({...prevState,'details':result.payload.data}))
            }
        })
    }
    
    const handleModalClose = () => {
        setState((prevState)=> ({...prevState, 'show_modal': false}))
    }


    return (
        <Layout title="Business Details">
            <Container md className="mt-5">
                <Row className="justify-content-center">
                    <Col xs={12} lg={9} md={8}>
                        {
                            state.details.merchant ? 
                            <Card className="shadow-sm border-0">
                                <div className="card-header bg-white text-muted fw-bold d-flex align-items-center">
                                    <div className="profile-avatar-sm border border-1">
                                        <img src={state.details.merchant.profile_photo || "https://via.placeholder.com/50"} alt="business" className="rounded-circle" />
                                    </div>
                                    <span className="ms-2">{state.details?.merchant?.store_name} - {id}</span>
                                    <button type="button" className="btn-plain rounded bg-type-warning ms-2 font-bold">{state.details.tickets || 0} Pending tickets</button>
                                    <Button 
                                        type="button" 
                                        className={`btn font-bold ms-auto ${state.details?.merchant?.status == "approved" ? "btn-light text-muted" : " btn-primary"}`}
                                        onClick={() => setState((prevState)=> ({...prevState, 'show_modal': true}))}
                                    >
                                        {
                                            state.details?.merchant?.status == "approved" ?
                                            <>
                                                <FontAwesomeIcon icon={'cancel'} /> &nbsp;
                                                Deactivate
                                            </>
                                            : 
                                            <>
                                                <FontAwesomeIcon icon={'lock-open'} /> &nbsp;
                                                Activate
                                            </>
                                        }
                                    </Button>
                                </div>
                                <Card.Body>
                                    <table className="table table-sm table-borderless w-100">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <small className="text-muted">Business Merchant ID</small> <br />
                                                    <p>{id}</p>
                                                </td>
                                                <td>
                                                    <small className="text-muted">Business Owner</small> <br />
                                                    <p>{`${state.details.merchant.first_name} ${state.details.merchant.last_name}`}</p>
                                                </td>
                                                <td>
                                                    <small className="text-muted">Date Joined</small> <br />
                                                    <p>{dateFormatter(state.details.merchant.date_joined)}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <small className="text-muted">Business name</small> <br />
                                                    <p>{state.details?.merchant?.store_name}</p>
                                                </td>
                                                <td>
                                                    <small className="text-muted">Business type</small> <br />
                                                    <p>{state.details.merchant.business_type}</p>
                                                </td>
                                                <td>
                                                    <small className="text-muted">Email address</small> <br />
                                                    <p>{state.details.merchant.email}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <small className="text-muted">Store city</small> <br />
                                                    <p>{state.details.merchant.store_city || ''}</p>
                                                </td>
                                                <td>
                                                    <small className="text-muted">Store address</small> <br />
                                                    <p>{state.details.merchant.store_address || ''}</p>
                                                </td>
                                                <td>
                                                    <small className="text-muted">Contact number</small> <br />
                                                    <p>{state.details.merchant.mobile}</p>
                                                </td>
                                            </tr>

                                            {
                                                state.details.merchant_banks && state.details.merchant_banks.length ? (
                                                    state.details.merchant_banks.map(bank => (
                                                        <tr key={bank.wallet_code}>
                                                            <td>
                                                                <small className="text-muted">Virtual account</small> <br />
                                                                <p>{bank.account_name}</p>
                                                            </td>
                                                            <td>
                                                                <small className="text-muted">Bank</small> <br />
                                                                <p>{bank.bank_name}</p>
                                                            </td>
                                                            <td>
                                                                <small className="text-muted">Virtual account </small> <br />
                                                                <p>{bank.account_number}</p>
                                                            </td>
                                                        </tr> 
                                                    ))
                                                ) : null
                                            }

                                            <tr>
                                                <td>
                                                    <small className="text-muted">Tickets</small> <br />
                                                    <button type="button" className="btn-plain text-warning rounded font-bold">{state.details.tickets || 0}</button>
                                                </td>
                                                <td>
                                                    <small className="text-muted">Status</small> <br />
                                                    {
                                                        state.details?.merchant?.status === 'approved' ? (
                                                            <button type="button" className="btn-plain bg-type-success rounded">Active</button>
                                                        ) :
                                                        <button type="button" className="btn-plain bg-type-danger rounded">Inctive</button>
                                                    }
                                                </td>
                                                <td>
                                                    <small className="text-muted">BVN</small> <br />
                                                    <p>{state.details?.merchant?.bvn}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="text-left">
                                        <h6>About Business</h6>
                                        <p>
                                            {state.details?.merchant?.store_about}
                                        </p>
                                    </div>
                                    <div className="mt-2 mb-2">
                                        <button 
                                            type="button" 
                                            className="btn-plain rounded bg-type-primary px-5 py-2 m-2"
                                            onClick={() => setState((prevState)=> ({...prevState, print_screen: true}))}
                                        >
                                            <FontAwesomeIcon icon={'print'} /> Print Banner
                                        </button>
                                        {
                                            state.details.merchant?.wallet_locked ? (
                                                <button 
                                                    type="button" 
                                                    className="btn-plain rounded bg-primary text-white px-5 py-2 m-2"
                                                    onClick={ () => handleManagement("unlock") }
                                                >
                                                    Unlock Wallets 
                                                </button>
                                            ) :
                                            <button 
                                                type="button" 
                                                className="btn-plain rounded bg-danger text-white px-5 py-2 m-2"
                                                onClick={ () => handleManagement("lock") }
                                            >
                                                Lock Wallets 
                                            </button>
                                        }
                                    </div>
                                    <div className="mt-3 mb-3 border-1 p-3 rounded border">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <button type="button" className="btn-plain">Transaction Overview</button>
                                            <button 
                                                type="button" 
                                                className="btn-plain text-primary" 
                                                onClick={() => navigate(`/transactions/?merchant=${state.details.merchant.merchant_id}`)}
                                            >
                                                View Transaction History  &nbsp;
                                                <FontAwesomeIcon icon={'arrow-right-long'} />
                                            </button>
                                        </div>
                                        <div className="d-flex mt-3 mb-3">
                                            <DataCardSm
                                                title='Funds'
                                                text='Wallet balance'
                                                amount={formatCurrency(state.details.transaction_overview.total_funds).with_currency}
                                                className='bg--primary'
                                            />
                                            <DataCardSm
                                                title='Transactions'
                                                text='All transactions'
                                                amount={formatCurrency(state.details.transaction_overview.total_transactions).with_currency}
                                                className='bg--success'
                                            />
                                            <DataCardSm
                                                title='Bills Payment'
                                                text='Bills'
                                                amount={formatCurrency(state.details.transaction_overview.total_bills_payment).with_currency}
                                                className='bg--secondary'
                                            />
                                            <DataCardSm
                                                title='Paycode Payment'
                                                text='Paycode payments'
                                                amount={formatCurrency(state.details.transaction_overview.total_paycode_payment).with_currency}
                                                className='bg--dark'
                                            />
                                        </div>
                                    </div>
                                    {/* Subscription history */}
                                    <div className="mt-3 mb-3 border-1 p-3 rounded border">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <button type="button" className="btn-plain">Subscription History</button>
                                            <button type="button" className="btn-plain text-primary">
                                                See all  &nbsp;
                                                <FontAwesomeIcon icon={'arrow-right-long'} />
                                            </button>
                                        </div>
                                        <div className="border border-1 rounded p-2 mt-3 mb-3">
                                            <table className="table table-sm table-borderless w-100">
                                                <thead>
                                                    <tr className="text-center text-muted">
                                                        <th>Subscription Date</th>
                                                        <th>Amount</th>
                                                        <th>Period <span className="text-muted">(Months)</span> </th>
                                                        <th>Plan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4} className="text-center">No subscription entries</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <p>Attached Files</p>
                                        <button 
                                            type="button" 
                                            className="btn-plain bg-light p-2 px-4 rounded m-1"
                                            disabled={state.details.merchant.means_of_id_doc === null}
                                            onClick={ () => window.location.href = state.details.merchant.means_of_id_doc}
                                        >
                                            Means of ID
                                        </button>
                                        <button 
                                            type="button" 
                                            className="btn-plain bg-light p-2 px-4 rounded m-1"
                                            disabled={state.details.merchant.document === null}
                                            onClick={ () => window.location.href = state.details.merchant.document}
                                        >
                                            Document
                                        </button>
                                        <button 
                                            type="button" 
                                            className="btn-plain bg-light p-2 px-4 rounded m-1"
                                            disabled={state.details.merchant.cac_document === null}
                                            onClick={ () => window.location.href = state.details.merchant.cac_document}
                                        >
                                            CAC Document
                                        </button>
                                    </div>
                                </Card.Body>
                            </Card>
                            : null
                        }
                    </Col>
                </Row>
                <Modal show={state.show_modal} onHide={handleModalClose} size='md'>
                    <Modal.Body className="py-5 px-5">
                        <div className="text-center text-muted">
                            <FontAwesomeIcon icon={'briefcase'} style={{fontSize: "35px"}}/>
                            {
                                state.details?.merchant?.status === "approved" ?
                                    <>
                                        <h5 className="mt-4">Deactivate {state.details?.merchant?.store_name}!!!</h5>
                                        <p className="text-sm">Are you sure you want to deactivate this business? This business wouldn't be able to perform any transactions</p>
                                    </>
                                : 
                                    <>
                                        <h5 className="mt-4">Activate {state.details?.merchant?.store_name}!!!</h5>
                                        <p className="text-sm">Are you sure you want to activate this business? This business will be able to perform transactions</p>
                                    </>
                            }
                        </div>
                        {
                            state.details?.merchant?.status === "approved" ?
                                <>
                                    <div className="form-group">
                                        <label htmlFor="reason">Reasons for deactivation (optional)</label>
                                        <select name="reason" className="form-control">
                                            <option value="" selected disabled hidden>Select an option</option>
                                            <option value="1">Low revenue</option>
                                            <option value="2">Insufficient members</option>
                                            <option value="3">Other reasons</option>
                                        </select>
                                    </div>
                                </>
                            : 
                                null
                        }
                        <div className="form-group mt-3 text-center">
                            <button 
                                type="button" 
                                className="btn-plain border border-1 rounded p-2 m-2 px-5"
                                onClick={() => setState((prevState)=> ({...prevState, 'show_modal': false}))}
                            >
                                Cancel
                            </button>
                            {
                                state.details?.merchant?.status === "approved" ?
                                    <button 
                                        type="button" 
                                        className="btn-plain bg-light rounded m-2 p-2 px-4"
                                        onClick={ () => handleManagement("deactivate") }
                                    >
                                        Deactivate business
                                    </button>
                                :
                                    <button 
                                        type="button" 
                                        className="btn-plain bg-success text-white rounded m-2 p-2 px-4"
                                        onClick={ () => handleManagement("activate") }
                                    >
                                        Activate business
                                    </button>
                            }
                        </div>
                    </Modal.Body>
                </Modal>

                {
                    state.details.merchant ? 
                        <Modal fullscreen show={state.print_screen} onHide={() => setState((prevState)=> ({...prevState, print_screen: false}))} size='lg' className="bg-dark">
                            <Modal.Body className="p-5">
                                <div className="bg-light mt-4 mb-4 p-2">
                                    <Row>
                                        <Col md={4}>
                                            <Card className="border-0 h-50k">
                                                <Card.Body>
                                                    <h5>SCAN HERE TO PAY</h5>
                                                    <div className="text-center">
                                                        <QRCodeSVG 
                                                            value={`${window.location.origin}/payments/qr-pay/${state.details.merchant.merchant_id}`}
                                                            size={200}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            <Card className="border-0 mt-3">
                                                <Card.Body>
                                                    <img  
                                                        src={card_logo} 
                                                        alt="business_card"
                                                        className="img-fluid"
                                                    />
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                        <Col md={8}>
                                            <Card className="border-0">
                                                <Card.Body>
                                                    <h5>{state.details?.merchant?.store_name}</h5>
                                                    <Table size="sm" className="table-color">
                                                        <tbody>
                                                            <tr className="border-bottom">
                                                                <td>Merchant ID</td>
                                                                <td>
                                                                    <h6 className="text-primary">{ state.details.merchant.merchant_id }</h6>
                                                                </td>
                                                            </tr>
                                                            {
                                                                state.details.merchant_banks?.length ? (
                                                                    state.details.merchant_banks?.map((account, index) => (
                                                                    <tr className="border-bottom" key={index}>
                                                                        <td>{account?.bank_name}</td>
                                                                        <td>
                                                                            <h5 className="font-bold">{account?.account_number}</h5>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                    ) : null
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                            <div className="d-flex align-items-center justify-content-betweden gap-3 mt-4">
                                                {
                                                    state.details.merchant_banks?.length ? (
                                                        state.details.merchant_banks?.map((account, index) => (
                                                            <div className="rounded-16 print--bg text-white p-3 col-6" key={index}>
                                                                <div className="d-flex gap-4 mb-3 align-items-center">
                                                                    <div 
                                                                        className="thumbnail-image rounded-circle border border-1 bg-white d-flex align-items-center justify-content-center" 
                                                                        style={{ height: "50px", width: "50px" }}
                                                                    >
                                                                        <img  
                                                                            src={account.bank_slug === 'wema-bank' ? wema : bank} 
                                                                            alt={account.bank_slug} 
                                                                            width={40} 
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="mb-0">{account?.account_number}</h6>
                                                                        <p className="small">{account?.account_name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="bg---primary-light rounded py-1 px-2 w-50 ms-auto">{account?.bank_name}</div>
                                                            </div>
                                                        ))
                                                    ) : null
                                                }
                                            </div>
                                            <div className="bg---primary-light w-50 ms-auto text-white mt-3 rounded p-1">
                                                <p className="text--sm m-0">Generate your <strong>PAYCODE</strong> from www.wallx.co</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="text-center d-flex justify-content-between">
                                    <Button 
                                        type="button" 
                                        variant="outline-primary" 
                                        size="lg"
                                        className="no-print"
                                        onClick={ () => setState((prevState)=> ({...prevState, print_screen: false})) }
                                    >
                                        <span className="px-2">Cancel</span>
                                    </Button>
                                    <Button 
                                        type="button" 
                                        variant="primary" 
                                        size="lg"
                                        className="no-print"
                                        onClick={ () => window.print()}
                                    >
                                        <img src={printer} alt="printer" className="text-white" />
                                        <span className="px-2">Print</span>
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    : null
                }
            </Container>
        </Layout>
    );
}


export default BusinessDetails;