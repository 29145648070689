import { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button, Modal, Spinner } from "react-bootstrap";
import Logo from "../../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
// import avatar from '../../assets/images/landing_page/mail2.svg';
import { useDispatch, useSelector } from "react-redux";
import { bvnVeirifcation, getBankList, getCountryList } from '../../redux/slices/account.service';
import { createBusiness } from '../../redux/slices/users';
import {  getProfile } from '../../redux/slices/auth';
import toast from "react-hot-toast";
import { clearMessage, setMessage } from "../../redux/slices/message";
import Select from 'react-select'
import AccountService from "../../services/account.service";
import AlertModal from "../../components/modal/AlertModal";
import {_nigerian_states} from "../../utils/constants/states";

const ProfileSetup = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { message } = useSelector((state) => state.message)
    const { isAuthenticated, user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)

    useEffect(() => {
        if (message && message?.status && message?.status === 'False') {
            toast.error(message.message) 
            dispatch(clearMessage())
        }
        else if(message && message.status === 'True') {
            setAlertMessage(message.message)
            setAlertModal(true)
            setModalShow(false)
            setTotalStep(3)
            dispatch(clearMessage())
        }
        dispatch(getProfile())
        .then(() => {
            if(business && business.user_name) {
                setPayload(business)
            }
            if(user && user.store_created && !user.pin_created) {
                setStep(3)
            }
            else if(user && user.store_created && user.pin_created && isAuthenticated) {
                navigate('/dashboard')
            }
        })
    }, [message, dispatch])

    useEffect(() => {
        dispatch(getBankList())
        .then((e) => {
            console.log("==================>",e)
            if(typeof(e.payload) === 'object' && e.payload?.success !== 'False') {
                const options =  e.payload?.map(bank =>  { 
                   return {value: bank.code, label: bank.name} 
                })
                setBanks(options)
            }
        } )
        dispatch(getCountryList())
        .then((e) => {
            if(typeof(e.payload) === 'object') {
                const options =  e.payload?.map(country =>  { 
                   return {value: country.id, label: country.name, id: country.id} 
                })
                setCountries(options)
            }
        } )
        
    }, [dispatch, user, isAuthenticated, navigate])


    const request_data = {
        bvn: '', 
        business_name: '', 
        business_about: '',
        address: '',
        business_address: '',
        business_type: '',
        code: '',
        phone_number: '',
        country: '',
        city: '' ,
        state: '',
        means_of_type: '',
        director_name: '',
        director_bvn: '',
        rc_number: '',
        bvn_verified: false,
        account_verified: false,
        account_name: '',
        bank: '',
    }

    const [validated, setValidated] = useState(false);
    const [payload, setPayload] = useState(request_data)
    const [modalShow, setModalShow] = useState(false)
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false)
    const [banks, setBanks] = useState([])
    const [profile_photo, setProfilePhoto] = useState(null)
    const [means_of_id, setMeans] = useState(null)
    const [photo_file, setPhotoFile] = useState(null)
    const [spinna, setSpinner] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [btn_active, setBtn] = useState(false)
    const [total_step, setTotalStep] = useState(3)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertModal, setAlertModal] = useState(false)
    const [states, setStates] = useState([])
    const [cac, setCac] = useState(null)
    const [mem, setMem] = useState(null)
    const [countries, setCountries] = useState([])
    const [verified, setVerified] = useState(false)
    const [verification_message, setVerificationMessage] = useState(false)
    const [errors, setErrors] = useState({})
    const [isFormValid, setIsFormValid] = useState({step: step, status: false})
    const [account_added, setAccountAdded] = useState(false)

    let formdata = new FormData()

    

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setModalShow(true)

      setValidated(true);
    };

    const moveNext = () => {
        let newStep = step < 3 ? step+1 : step
        if(step >= total_step) {
            setModalShow(true)
            return
        }
        setStep(newStep)
        setIsFormValid({step: step, status: false})
    }

    const movePrev = () => {
        let newStep = step > 1 ? step-1 : step
        setStep(newStep)
        setIsFormValid({step: step, status: true})
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        handleValidation(event)
        setPayload((prevState)=> ({...prevState,[name]:value}))
        if(name === 'dob' && payload.bvn) {
            bvnVeirifcation(event)
        }
    }

    const handleValidation = (event) => {
        const { name, value } = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setBtn(false)
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
        if(name === 'transaction_pin' || name === 'transaction_pin_confirmation') {
            if(payload.transaction_pin !== payload.transaction_pin_confirmation){
                setErrors((prevState) => ({...prevState, [name]: "Transaction PINs don't match" }))
                // console.log(errors)
            } 
            else {
                delete errors[name]
                delete errors['transaction_pin']
                setErrors(errors)
            }
            // console.log(errors)
        }

        checkFormValidity()

    }
    
    const checkFormValidity = () => {
        if(step === 1  && (payload.business_name && payload.business_about && 
            payload.business_address && payload.business_type &&
            payload.city && payload.state && payload.phone_number 
            && payload.country && Object.keys(errors).length === 0) 
        ) {
            setIsFormValid({step: step, status: true})
            // setIsFormValid({step: step+1, status: false})
        }
        else if(step === 2 && payload.business_type === 'Registered Business' &&
            (payload.director_bvn && payload.director_name && payload.dob &&
            payload.rc_number && cac && mem && means_of_id && payload.bvn &&
            Object.keys(errors).length === 0 )
        ) {
            setIsFormValid({step: step, status: true})
        }
        else if(step === 2 &&
            (payload.dob && payload.bvn && 
            payload.means_of_type &&  means_of_id &&
            Object.keys(errors).length === 0 )
        ) {
            setIsFormValid({step: step, status: true})
        }
        else if(step === 3 &&
            (payload.bank && payload.account_number && 
            payload.code && payload.transaction_pin && payload.transaction_pin_confirmation && 
            Object.keys(errors).length === 0 )
        ) {
            setIsFormValid({step: step, status: true})
        }
        else {
            setIsFormValid({step: step, status: false})
        }

        // console.log(isFormValid)
        
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        formdata.append(event.target.name, file)
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            if(event.target.name === 'passport') {
                setProfilePhoto(reader.result);
                setPhotoFile(file)
            }
            else if(event.target.name === 'cac') {
                setCac(file)
            }
            else if(event.target.name === 'mem') {
                setMem(file)
            }
            else if(event.target.name === 'means_of_id_file') {
                setMeans(file)
            }
        };
        checkFormValidity()
    }

    const handleBankSelect = (event) => {
        setPayload((prevState) => ({...prevState, code:event.value}))
        setPayload((prevState) => ({...prevState, bank:event.label}))
    }

    const bvnVerification = async (event) => {
        const { value } = event.target

        if(value.length < 11 || (payload.dob === '' || payload.dob === undefined)) return;
        setSpinner(true)
        setErrorMessage(null)
        try {
            const dateParts = payload.dob.split('-');
            const dob = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
            setPayload((prevState)=> ({...prevState, bvn: value}))
            const response = await AccountService.verifyBvn(value, dob)
            if(response.data) {
                setErrorMessage(null)
                setAlertMessage('BVN verification successful.')
                setPayload((prevState)=> ({...prevState, bvn_verified: true}))
                toast.success('BVN verification successful.')
            }
        } catch (error) {
            const message = error?.message
            setErrorMessage(message)
            toast.error(message)
        }
        finally {
            setSpinner(false)
        }
        
    }

    const createBusinessInfo = () => {
        const dateParts = payload.dob.split('-');
        const dob = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`
        formdata.append('business_name', payload.business_name)
        formdata.append('business_type', payload.business_type)
        formdata.append('business_about', payload.business_about)
        formdata.append('business_address', payload.business_address)
        formdata.append('address', payload.business_address)
        formdata.append('bvn', payload.bvn)
        formdata.append('country', payload.country)
        formdata.append('dob', dob)
        formdata.append('state', payload.state)
        formdata.append('city', payload.city)
        formdata.append('mobile', payload.phone_number)
        formdata.append('id-proof', payload.means_of_type)
        formdata.append('document', means_of_id)
        formdata.append('bvn_verified', payload.bvn_verified)
        // formdata.append('bank', payload.bank)
        formdata.append('profile_photo', photo_file)
        formdata.append('passport', photo_file)
        if(payload.business_type === 'Registered Business') {
            formdata.append('cac', cac)
            formdata.append('mem', mem)
            formdata.append('director_name', payload.director_name)
            formdata.append('director_bvn', payload.director_bvn)
            formdata.append('rc_number', payload.rc_number)
        }
        setLoading(true)
        dispatch(createBusiness(formdata))
        .finally(() => setLoading(false))
    }

    const businessSelection = (event) => {
        const { value } = event.target
        setTotalStep(value === 'Starter Business' ? 2 : 2)
        setPayload((prevState) => ({...prevState, business_type: value}))
        handleValidation(event)
    }

    const addAccount = () => {
        setAlertModal(false)
        if(step === 3) {
            navigate('/')
        }
        else {
            let newStep = step < 3 ? step + 1 : step
            setStep(newStep)
        }
    }

    const countryStateSelection = (event) => {
        setPayload((prevState) => ({...prevState, country: event.id}))
        setPayload((prevState) => ({...prevState, country_name: event.label}))
        const state_list = _nigerian_states.map((item) => {
            return {value: item.state.name, label: item.state.name}
        })
        const data = event.label === 'Nigeria' ? state_list : [];
        setStates(data)
        if(event.label !== 'Nigeria') {
            setPayload((prevState) => ({...prevState, bvn: 'NOT_NEEDED_'}))
        }
    }

    const stateSelection = (event) => {
        setPayload((prevState) => ({...prevState, state: event.value}))
    }

    const addBankDetails = async () => {
        const req_data = { 
            bank: payload.bank,  
            bank_verified: payload.account_verified, 
            code: payload.code,
            account_number: payload.account_number,
            account_name: payload.account_name,
            pin: payload.transaction_pin,
            'confirm-pin': payload.transaction_pin_confirmation,
        }

        setSpinner(true)
        setErrorMessage(null)
        try {
            const response = await AccountService.addAccountDetails(req_data)
            if(response.success) {
                setErrorMessage(null)
                setAccountAdded(true)
                moveNext()
                toast.success(response.message)
            }
            // console.log("Account verification", response)
        } catch (error) {
            // console.log(error)
            const message = error?.message
            setErrorMessage(message)
            toast.error(message)
        }
        finally {
            setSpinner(false)
        }

    }

    const verifyAccountNumber = async (event) => {
        const { value } = event.target
        if(value.length < 10 || (payload.code === '' || payload.code === undefined)) return;
        setSpinner(true)
        setErrorMessage(null)
        try {
            const response = await AccountService.validateAccountNumber({code: payload.code, account_number: value})
            if(response.data) {
                setMessage({message: response.message, statue: 'True'})
                setPayload((prevState)=> ({...prevState, account_number: response.data.account_number}))
                setPayload((prevState) => ({...prevState, account_name: response.data.account_name}))
                setPayload((prevState) => ({...prevState, account_verified: true}))
                setErrorMessage(null)
                setVerified(true)
                setVerificationMessage('Account verification successful.')
            }
            // console.log("Account verification", response)
        } catch (error) {
            console.log(error)
            const message = error?.message
            setErrorMessage(message)
            toast.error(message)
        }
        finally {
            setSpinner(false)
        }
        
    }

    const dashboard = () => {
        navigate('/dashboard')
    }

    return (
        <Row className="full-height">
            <Col md={7} lg={7} sm={12} className="px-5 p-md-2 p-sm-2 mb-3">
                <Container className="mt-2 px-5">
                    <Logo variant="dark"/>
                </Container>
                <div className={ step !==  1 ? 'd-none' : null}>
                    <Container className="px-5">
                        <h4 className="text-primary mt-2">Set-Up Your Business Profile</h4>
                        <p className="text-dark">
                            Enter your credentials and start applying for project write-up.
                        </p>
                        <div className="profile-avatar mt-4">
                            {
                                profile_photo ? (
                                    <img src={profile_photo} className="rounded-circle" alt="profile" />
                                )
                                :
                                <FontAwesomeIcon icon="fa fa-camera" style={{fontSize: "20px"}} />
                            }
                        </div>
                    </Container>
                    <Container className="px-5 mt-2 padding-horizontal">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Business Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Business Name"
                                        name="business_name"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        size="lg"
                                        value={payload?.business_name}
                                    />
                                    {
                                        errors['business_name'] && 
                                        <Form.Text className="text-danger">{errors['business_name']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">About Business</Form.Label>
                                    <textarea
                                        required
                                        type="textarea"
                                        placeholder="About Business"
                                        name="business_about"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        className="form-control"
                                        value={payload?.business_about}
                                    ></textarea>
                                    {
                                        errors['business_about'] && 
                                        <Form.Text className="text-danger">{errors['business_about']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Business Type</Form.Label>
                                    <Form.Select
                                        required
                                        name="business_type"
                                        onChange={businessSelection}
                                        className="form-control"
                                        size="lg"
                                        value={ payload.business_type }
                                    >
                                        <option  value="">Select an option</option>
                                        <option  value="Starter Business">Starter Business</option>
                                        <option  value="Registered Business"> Regsitered Business </option>
                                    </Form.Select>
                                    {
                                        errors['business_type'] && 
                                        <Form.Text className="text-danger">{errors['business_type']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md={6} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Phone Number</Form.Label>
                                    <Form.Control
                                        required
                                        type="tel"
                                        placeholder="+234"
                                        name="phone_number"
                                        minLength={11}
                                        maxLength={11}
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        size="lg"
                                        value={payload?.phone_number}
                                    />
                                    {
                                        errors['phone_number'] && 
                                        <Form.Text className="text-danger">{errors['phone_number']}</Form.Text>
                                    }
                                </Form.Group>
                                {/* <Form.Group as={Col} md={6} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Business Website (optional) </Form.Label>
                                    <Form.Control
                                        type="url"
                                        placeholder="https://example.com"
                                        name="business_website"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        size="lg"
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                    <FontAwesomeIcon icon="fa fa-circle-info"/>
                                        <span className="px-1">Enter a valid url</span>
                                    </Form.Control.Feedback>
                                </Form.Group> */}
                                <Form.Group as={Col} md={6} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Address</Form.Label>
                                    <Form.Control 
                                        required
                                        type="text"
                                        placeholder="Address"
                                        name="business_address"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        size="lg"
                                        value={payload?.business_address}
                                    />
                                    {
                                        errors['business_address'] && 
                                        <Form.Text className="text-danger">{errors['business_address']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validateCountry" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Country</Form.Label>
                                    <Select 
                                        name="country" 
                                        onChange={countryStateSelection} 
                                        options={countries}
                                        selected={payload.country_name}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: "45px",
                                                backgroundColor: "#f2f2f2"
                                              }),
                                        }}
                                    />
                                    {
                                        errors['country'] && 
                                        <Form.Text className="text-danger">{errors['country']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md={6} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">State</Form.Label>
                                    {
                                        states.length ?
                                        (
                                            <Select 
                                                name="state" 
                                                onChange={stateSelection} 
                                                options={states}
                                                selected={payload?.state}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: "45px",
                                                        backgroundColor: "#f2f2f2"
                                                      }),
                                                }}
                                            />
                                        ) : 
                                        (
                                            <Form.Control
                                                type="text"
                                                required
                                                name="state"
                                                placeholder="State"
                                                onChange={handleInput}
                                                onKeyUp={handleValidation}
                                                size="lg"
                                                className="form-control"
                                                value={payload?.state}
                                            />
                                        )
                                    }
                                    {
                                        errors['state'] && 
                                        <Form.Text className="text-danger">{errors['state']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md={6} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        name="city"
                                        placeholder="City"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        size="lg"
                                        className="form-control"
                                        value={payload?.city}
                                    />
                                    {
                                        errors['city'] && 
                                        <Form.Text className="text-danger">{errors['city']}</Form.Text>
                                    }
                                </Form.Group>
                            </Row>
                            <div className="d-flex justify-content-between gap-2">
                                <Button 
                                    type="button" 
                                    variant="outline-primary" 
                                    className="form-control"
                                    onClick={ movePrev }
                                    size="lg"
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    variant="primary" 
                                    className="form-control"
                                    onClick={ moveNext }
                                    size="lg"
                                    disabled={!isFormValid.status && step === 1}
                                >
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    </Container>
                </div>
                <div className={ step !==  2 ? 'd-none' : null} >
                    <Container className="px-5">
                        <h4 className="text-primary mt-2">Secure Your Account</h4>
                        <p className="text-dark">
                            Enter your credentials and start applying for project write-up.
                        </p>
                    </Container>
                    <Container className="px-5 mt-2">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Date of Birth</Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        placeholder="Date of Birth"
                                        name="dob"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        size="lg"
                                    />
                                    {
                                        errors['dob'] && 
                                        <Form.Text className="text-danger">{errors['dob']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustomBvn" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">BVN <Spinner animation={ spinna && !payload.bvn_verified ? "border" : null} variant="primary" role="status" size="sm"></Spinner> </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="BVN"
                                        name="bvn"
                                        onChange={bvnVerification}
                                        onKeyUp={handleValidation}
                                        maxLength={11}
                                        minLength={11}
                                        size="lg"
                                        disabled={payload.bvn_verified}
                                    />
                                    {
                                        errorMessage ? (
                                            <small className="text-danger">{errorMessage}</small>
                                        ) : null
                                    }
                                    {
                                        errors['bvn'] && 
                                        <Form.Text className="text-danger">{errors['bvn']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustomMeans" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Select a means of ID</Form.Label>
                                    <Form.Select
                                        required
                                        name="means_of_type"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        size="lg"
                                        className="form-control"
                                        value={ payload.means_of_type }
                                    >
                                        <option value="">Select an option</option>
                                        <option value="Voters Card">Voters Card</option>
                                        <option value="Drivers License">Drivers License</option>
                                        <option value="National ID Card">National ID Card</option>
                                        <option value="International Passport">International Passport</option>
                                    </Form.Select>

                                    {
                                        errors['means_of_type'] && 
                                        <Form.Text className="text-danger">{errors['means_of_type']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom011" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Means of ID</Form.Label>
                                    <label htmlFor="means_of_id" className="custom-file-select form-control form-control-lg p-3">
                                       <FontAwesomeIcon icon="fa fa-arrow-up-from-bracket" /> Upload document
                                        <input
                                            type="file"
                                            name="means_of_id_file"
                                            onChange={handleFileSelect}
                                            accept="image/*"
                                            size="lg"
                                            id="means_of_id"
                                        />
                                    </label>
                                    <p className="px-1 m-0 text-secondary text-sm">Only png or jpg file is allowed. Max files size 2mb</p>
                                </Form.Group>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom0111" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Passport Photograph</Form.Label>
                                    <label htmlFor="passport" className="custom-file-select form-control form-control-lg p-3">
                                       <FontAwesomeIcon icon="fa fa-arrow-up-from-bracket" /> Upload document
                                        <input
                                            type="file"
                                            name="passport"
                                            onChange={handleFileSelect}
                                            accept="image/*"
                                            size="lg"
                                            id="passport"
                                        />
                                    </label>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                    <FontAwesomeIcon icon="fa fa-circle-info"/>
                                        <span className="px-1">Only png or jpg file is allowed. Max files size 2mb</span>
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Registered business information */}
                                {
                                    payload.business_type === 'Registered Business' ? (
                                    <>
                                        <Form.Group as={Col} md={6} className="mb-3">
                                            <Form.Label className="mb-0 text-sm">RC Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="RC Number"
                                                name="rc_number"
                                                onChange={handleInput}
                                                onKeyUp={handleValidation}
                                                size="lg"
                                                value={payload?.rc_number}
                                            />
                                            {
                                                errors['rc_number'] && 
                                                <Form.Text className="text-danger">{errors['rc_number']}</Form.Text>
                                            }
                                        </Form.Group>

                                        <Form.Group as={Col} md={6} className="mb-3">
                                            <Form.Label className="mb-0 text-sm">Director BVN</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Director BVN"
                                                name="director_bvn"
                                                onChange={handleInput}
                                                onKeyUp={handleValidation}
                                                maxLength={11}
                                                minLength={11}
                                                size="lg"
                                                value={payload?.director_bvn}
                                            />
                                            {
                                                errors['director_bvn'] && 
                                                <Form.Text className="text-danger">{errors['director_bvn']}</Form.Text>
                                            }
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationDirectorName" className="mb-3">
                                            <Form.Label className="mb-0 text-sm">Director Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Director Name"
                                                name="director_name"
                                                onChange={handleInput}
                                                onKeyUp={handleValidation}
                                                size="lg"
                                                value={payload?.director_name}
                                            />
                                            {
                                                errors['director_name'] && 
                                                <Form.Text className="text-danger">{errors['director_name']}</Form.Text>
                                            }
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom011" className="mb-3">
                                            <Form.Label className="mb-0 text-sm">CAC Document</Form.Label>
                                            <label htmlFor="cac" className="custom-file-select form-control form-control-lg p-3">
                                            <FontAwesomeIcon icon="fa fa-arrow-up-from-bracket" /> Upload document
                                                <input
                                                    type="file"
                                                    name="cac"
                                                    onChange={handleFileSelect}
                                                    accept="image/*"
                                                    size="lg"
                                                    id="cac"
                                                />
                                            </label>
                                            <p className="px-1 m-0 text-secondary text-sm">Only png or jpg file is allowed. Max files size 2mb</p>
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustomMem" className="mb-3">
                                            <Form.Label className="mb-0 text-sm">Mem</Form.Label>
                                            <label htmlFor="mem" className="custom-file-select form-control form-control-lg p-3">
                                                <FontAwesomeIcon icon="fa fa-arrow-up-from-bracket" /> Upload document
                                                <input
                                                    type="file"
                                                    name="mem"
                                                    onChange={handleFileSelect}
                                                    accept="image/*"
                                                    size="lg"
                                                    id="mem"
                                                />
                                            </label>
                                            <p className="px-1 m-0 text-secondary text-sm">Only png or jpg file is allowed. Max files size 2mb</p>
                                        </Form.Group>
                                    </>
                                ) : null}
                                {/* Registered business information */}

                                <Form.Group>
                                    <p className="text-muted text-sm">To avoid delays when verifying account, Please make sure the below</p>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        className="text-sm text-muted"
                                        required
                                        label="Chosen credentials is not expired"
                                        feedback="Agree before submitting."
                                        feedbackType="invalid"
                                        name="terms"
                                        onChange={handleInput}
                                    />
                                    {
                                        errors['terms'] && 
                                        <Form.Text className="text-danger">{errors['terms']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        className="text-sm text-muted"
                                        required
                                        label="Document should  be in good condition and clearly visble"
                                        feedback="Agree before submitting."
                                        feedbackType="invalid"
                                        name="agreement"
                                        onChange={handleInput}
                                    />
                                    {
                                        errors['agreement'] && 
                                        <Form.Text className="text-danger">{errors['agreement']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        className="text-sm text-muted"
                                        required
                                        label="Make sure the is no light glare on the document "
                                        feedback="Agree before submitting."
                                        feedbackType="invalid"
                                        name="glare"
                                        onChange={handleInput}
                                    />
                                    {
                                        errors['glare'] && 
                                        <Form.Text className="text-danger">{errors['glare']}</Form.Text>
                                    }
                                </Form.Group>
                            </Row>
                            <div className="d-flex justify-content-between gap-2">
                                <Button 
                                    type="button" 
                                    variant="outline-primary" 
                                    className="form-control"
                                    onClick={ movePrev }
                                    size="lg"
                                >
                                    Previous
                                </Button>
                                <Button 
                                    variant="primary" 
                                    className="form-control"
                                    onClick={  moveNext }
                                    size="lg"
                                    disabled={!isFormValid.status && step === 2}
                                >
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    </Container>
                </div>
                <div className={ step !==  3 ? 'd-none' : null}>
                    <Container className="px-5 mt-5">
                        <h4 className="text-primary mt-2">Set your Payment Method</h4>
                        <p className="text-dark">
                            Set your transaction pin and so on
                        </p>
                    </Container>
                    <Container className="px-5 mt-2">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Bank Name</Form.Label>
                                    <Select 
                                        name="bank" 
                                        onChange={handleBankSelect} 
                                        options={banks}
                                        selected={payload.bank}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: "45px",
                                                backgroundColor: "#f2f2f2"
                                              }),
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom0231" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Account Number <Spinner animation={ spinna && !payload.account_verified ? "border" : null} variant="primary" role="status" size="sm"></Spinner></Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="e.g: 0000000000"
                                        name="account_number"
                                        onChange={verifyAccountNumber}
                                        maxLength={10}
                                        minLength={10}
                                        size="lg"
                                        onKeyUp={handleValidation}
                                        value={payload?.account_number}
                                    />
                                    {
                                        errorMessage || verified ? (
                                            <small className={ verified ? "text-success" : "text-danger"}>{errorMessage || verification_message}</small>
                                        ) : null
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom0561" className="mb-1 mt-4">
                                    <p>Set your Transaction Pin</p>
                                </Form.Group>

                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom02313" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Transaction Pin</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        placeholder="e.g: 1234"
                                        name="transaction_pin"
                                        onChange={handleInput}
                                        maxLength={4}
                                        minLength={4}
                                        size="lg"
                                        onKeyUp={handleValidation}
                                        value={payload?.transaction_pin}
                                    />
                                    {
                                        errors['transaction_pin'] && 
                                        <Form.Text className="text-danger">{errors['transaction_pin']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom023131" className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Confirm Transaction Pin</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        placeholder="e.g: 1234"
                                        name="transaction_pin_confirmation"
                                        onChange={handleInput}
                                        maxLength={4}
                                        minLength={4}
                                        size="lg"
                                        onKeyUp={handleValidation}
                                        value={payload?.transaction_pin_confirmation}
                                    />
                                    {
                                        errors['transaction_pin_confirmation'] && 
                                        <Form.Text className="text-danger">{errors['transaction_pin_confirmation']}</Form.Text>
                                    }
                                </Form.Group>

                            </Row>
                            <div className="d-flex justify-content-between gap-2">
                                <Button 
                                    type="button" 
                                    variant="outline-primary" 
                                    className="form-control"
                                    onClick={ movePrev }
                                    size="lg"
                                >
                                    Previous
                                </Button>
                                <Button 
                                    variant="primary" 
                                    className="form-control"
                                    onClick={ addBankDetails }
                                    size="lg"
                                    disabled={step === 3 && !isFormValid.status}
                                >
                                    Continue <Spinner animation={ spinna && !account_added ? "border" : null} variant="light" role="status" size="sm"></Spinner>
                                </Button>
                            </div>
                        </Form>
                    </Container>
                </div>
            </Col>
            <AlertModal
                text={alertMessage}
                onClick={ addAccount }
                show={alertModal}
                buttonText="Proceed"
            >
                <FontAwesomeIcon className="text-success" icon="check-circle" style={{ fontSize: "54px" }} />
            </AlertModal>
            <Col md={5} className="bg-primary p-0">
                <Container className="mt-5 mb-2 ">
                    <div className="text-right text-white">
                        <Button type="button" variant="primary" size="sm" disabled={!btn_active} onClick={moveNext}>
                            <span className="px-2">Skip</span>
                            <FontAwesomeIcon icon="fas fa-chevron-right" />
                        </Button>
                    </div>
                    <div className="verification-steps">
                        <ul>
                            <li>
                                <span>
                                    {step >= 1 ? <i className="lni lni-checkmark"></i> : 1}
                                </span>
                                <p className="pt-2">Email Verification <small>Fill your basics information</small></p>
                            </li>
                            <li>
                                <span>{ step >= 2 ? <i className="lni lni-checkmark"></i> : 2}</span>
                                <p className={step === 1 ? "pt-2 font-bold" : "pt-2"}>Set up your profile <small>Fill your business Informations</small></p>
                            </li>
                            <li>
                                <span>{ step >= 3 ? <i className="lni lni-checkmark"></i> : 3}</span>
                                <p className={step === 2 ? "pt-2 font-bold" : "pt-2"}>Secure your account <small>Fill all necessary Information</small></p>
                            </li>
                            <li>
                                <span>{ step >= 4 ? <i className="lni lni-checkmark"></i> : 4}</span>
                                <p className={step === 3 ? "pt-2 font-bold" : "pt-2"}>Set your Payment Method <small>Set your transaction pin and Payout bank</small></p>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-5 text-white">
                    </div>
                </Container>
                <Container className="mt-5 text-white px-5">
                    <Col>
                        <Button type="button" variant="light" className="bg-white mb-3">
                            <FontAwesomeIcon icon="fa fa-headset"/>
                        </Button>
                        <p className="lead mb-0">Having any troubles?</p>
                        <p>Feel free to contact us and we will always help you through the process.</p>
                        <Button type="button" variant="outline-light">Contact Us</Button>
                    </Col>
                </Container>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modalShow}
                    // onHide={() => setModalShow(false)}
                >
                    <Modal.Body className="p-5">
                        <Row>
                            <Col md={5} className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <img src={profile_photo || 'https://via.placeholder.com/100.png'} alt="avatar" className="rounded-circle border border-secondary" style={{height: "100px", width: "100px"}}/>
                                    <p className="text-primary mb-0">100%</p>
                                    <p className="mt-0">Bravo💪💪💪🔥🔥🔥</p>
                                </div>
                            </Col>
                            <Col md={7} className="modal-profile">
                                <h5>My Business Profile</h5>
                                <ul>
                                    <li>
                                        <span className="icon">
                                            <i className="lni lni-checkmark-circle text-primary" style={{fontSize: "20px"}}></i>
                                        </span>
                                        <div>
                                            <p className="pt-2">Email Verification <small className="text-muted">Verify your email address</small></p>
                                            <span>
                                                <i className="lni lni-chevron-right"></i>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="icon">
                                            <i className="lni lni-checkmark-circle text-primary" style={{fontSize: "20px"}}></i>
                                        </span>
                                        <div>
                                            <p className="pt-2">
                                                Business Set Up 
                                                <small className="text-muted">Fill your business details</small>
                                            </p>
                                            <span>
                                                <i className="lni lni-chevron-right"></i>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="icon">
                                            <i className="lni lni-checkmark-circle text-primary" style={{fontSize: "20px"}}></i>
                                        </span>
                                        <div>
                                            <p className="pt-2">
                                                Secure your account 
                                                <small className="text-muted">Fill necessary details</small>
                                            </p>
                                            <span>
                                                <i className="lni lni-chevron-right"></i>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="icon">
                                            <i className="lni lni-checkmark-circle text-primary" style={{fontSize: "20px"}}></i>
                                        </span>
                                        <div>
                                            <p className="pt-2">
                                                Set your Payment Method 
                                                <small className="text-muted">Add bank and set your transaction pin and so on</small>
                                            </p>
                                            <span>
                                                <i className="lni lni-chevron-right"></i>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                <div className="d-flex justify-content-between gap-3">
                                    {
                                        step <= 2 ? (
                                            <Button 
                                                type="button" 
                                                variant="outline-primary" 
                                                size="lg" 
                                                className="form-control"
                                                onClick={() => setModalShow(false)}
                                            >
                                                Previous
                                            </Button>
                                        ) : null
                                    }
                                    <Button 
                                        type="button" 
                                        variant="primary" 
                                        size="lg" 
                                        className="form-control" 
                                        onClick={step > 2 ? dashboard : createBusinessInfo}
                                    >
                                       {step <= 2 ? 'Set my profile' : 'Proceed'}  <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Col>
        </Row>
    );
}


export default ProfileSetup;